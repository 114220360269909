.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  /**/
  /*other default setting ...*/
  /**/
  --secondary-glow: conic-gradient(from 10deg at 50% 50%,
      #c1fe72 0deg,
      #ae77b2 55deg,
      #613E2C 120deg,
      #B22CB3 160deg,
      transparent 360deg);

  --third-glow: conic-gradient(from 90deg at 50% 50%,
      #CAC84E 0deg,
      #7A8334 160deg,
      #CA8061 120deg,
      #CA8061 55deg,
      transparent 360deg);
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  padding: 0;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
}

.mainDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  font-size: 3rem;
  flex-grow: 1;
  text-align: center;
  width: 100%;
}

.comingSoonButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: black;
  color: white;
  border-radius: 10px;
  margin: 2rem 0;
}

.subtitle, .bigTitle, .research {
  width: 100%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.subtitle {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
}

.bigTitle {
  margin-top: 10px;
  margin-bottom: 20px;
}

.research {
  margin: 2rem 0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-family: 'Poppins';
  padding: 10px 20px;
  margin-bottom: 40px;
  background-color: black;
  color: white;
}

.line {
  background-color: black;
  height: 20vh;
  width: 1px;
  margin: 0;
}

body {
  position: relative;
  overflow: hidden;
}

body::before,
body::after {
  content: '';
  position: fixed;
  z-index: -1;
  opacity: 0.8;
}

body::before {
  background: var(--third-glow);
  border-radius: 50%;
  width: 50vw;
  height: 50vw;
  filter: blur(90px);
  top: 25vh;
  right: -25vw;
  animation: animateBefore 7s cubic-bezier(0.47, 0, 0.745, 0.715) infinite;
}

body::after {
  background: var(--secondary-glow);
  border-radius: 50%;
  width: 500px;
  height: 700px;
  filter: blur(90px);
  bottom: -350px;
  left: -250px;
  animation: animateAfter 7s cubic-bezier(0.47, 0, 0.745, 0.715) infinite;
}
